import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { lazyWithPreload } from "utils";
import {
  PublicRoute,
  RequireAuth,
  StripeOnboardingRefreshUrl,
  StripeOnboardingReturnUrl,
} from "utils/routerGuards";
import PageLoader from "views/PageLayout/PageLoader/PageLoader";

import { PATHS } from "./constants";
import { RedirectToPass } from "./Redirect";

const ChallengeCategory = React.lazy(() =>
  import("./views/ChallengeCategory/ChallengeCategory")
);
const ClubChallenges = React.lazy(() =>
  import("./views/ClubChallenges/ClubChallenges")
);
const Clubs = React.lazy(() => import("./views/Clubs/Clubs"));
export const Discover = lazyWithPreload(() => import("./views/Discover"));
const LimitedEdition = React.lazy(() =>
  import("./views/Drops/LimitedEdition/LimitedEdition")
);
export const Drops = lazyWithPreload(() =>
  import("./views/Drops/PhaseList/PhaseList")
);
const EmailVerification = React.lazy(() =>
  import("./views/EmailVerification/EmailVerification")
);
const Error500 = React.lazy(() => import("./views/ErrorPage/Error500"));
const Error503 = React.lazy(() => import("./views/ErrorPage/Error503"));
export const ForgotPassword = lazyWithPreload(() =>
  import("./views/ForgotPassword")
);
export const Home = lazyWithPreload(() => import("./views/Home/Home"));
const Influencer = React.lazy(() => import("./views/Influencer"));
const InfluencerFollowers = React.lazy(() =>
  import("./views/Influencer/InfluencerFollowers")
);
export const Login = lazyWithPreload(() => import("./views/Login"));
const MeetingRoom = React.lazy(() => import("./views/MeetingRoom/MeetingRoom"));
// import Messages from "./views/Messages";
const NewChallengeDetails = React.lazy(() =>
  import("./views/NewChallengeDetails/NewChallengeDetails")
);
const NoMatch = React.lazy(() => import("./views/Nomatch"));
export const Notifications = lazyWithPreload(() =>
  import("./views/Notifications")
);
const BlockedAccounts = React.lazy(() =>
  import("./views/Settings/account/BlockedAccounts")
);
const SettingsAccountEmail = React.lazy(() =>
  import("./views/Settings/account/Email")
);
const PersonalInformation = React.lazy(() =>
  import("./views/Settings/account/PersonalInformation")
);
const Username = React.lazy(() => import("./views/Settings/account/Username"));
const DataManagement = React.lazy(() =>
  import("./views/Settings/resources/DataManagement")
);
const Help = React.lazy(() => import("./views/Settings/resources/Help"));
export const SignUp = lazyWithPreload(() => import("./views/Signup"));
const Card = React.lazy(() => import("views/Card/Card"));
const DropBuy = React.lazy(() => import("views/Drops/DropBuy"));
const DropBuySuccess = React.lazy(() => import("views/Drops/DropBuy/Success"));
const Kyc = React.lazy(() => import("views/KYC/KYC"));
export const NewProfile = lazyWithPreload(() =>
  import("views/NewProfile/NewProfileWrapper")
);
const CheckInfluencerHandle = React.lazy(() =>
  import("views/Nomatch/CheckInfluencerHandle")
);
const AddBankAccount = React.lazy(() =>
  import("views/Payments/AddBankAccount/AddBankAccount")
);
const BankAddress = React.lazy(() =>
  import("views/Payments/AddBankAccount/BankAddress")
);
const SelectBankType = React.lazy(() =>
  import("views/Payments/AddBankAccount/SelectBankType")
);
const AddCard = React.lazy(() => import("views/Payments/AddCard"));
const BillingAddress = React.lazy(() =>
  import("views/Payments/AddCard/BillingAddress")
);
const SelectPaymentMethod = React.lazy(() =>
  import("views/Payments/SelectPaymentMethod")
);
const Post = React.lazy(() => import("views/Post/index"));
const ReferralsInfluencers = React.lazy(() =>
  import("views/Referrals/ReferralsInfluencers")
);
const Rewards = React.lazy(() => import("views/Rewards"));
const Faq = React.lazy(() => import("views/Settings/Faq"));
const EmailNotificationsSettings = React.lazy(() =>
  import("views/Settings/Notifications/EmailNotifications")
);
const Policies = React.lazy(() => import("views/Settings/resources/Policies"));
// const ActivityHistory = React.lazy(() =>
//   import("views/Settings/security/ActivityHistory")
// );
const MFA = React.lazy(() => import("views/Settings/security/MFA"));
const ResetPassword = React.lazy(() =>
  import("views/Settings/security/ResetPassword")
);
export const Settings = lazyWithPreload(() =>
  import("views/Settings/Settings")
);
const Subscription = React.lazy(() => import("views/Settings/Subscription"));
const SettingsTransactions = React.lazy(() =>
  import("views/Settings/Transactions")
);
const Transactions = React.lazy(() => import("views/Transactions"));
const UserBlocked = React.lazy(() => import("views/UserBlocked/"));
const ComponentsTestPage = React.lazy(() => import("views/ComponentsTestPage"));
export const Referrals = lazyWithPreload(() =>
  import("./views/Referrals/Referrals")
);
const Earn = React.lazy(() => import("./views/Earn"));

const renderWithSuspense = (Component) => (
  <Suspense fallback={<PageLoader />}>
    <Component />
  </Suspense>
);

function Router() {
  let location = useLocation();

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route
            path="/"
            element={
              <Navigate
                to={PATHS.SIGN_UP}
                state={{ backRoute: location }}
                replace
              />
            }
          />
          <Route path={PATHS.LOGIN} element={renderWithSuspense(Login)} />
          <Route path={PATHS.LOGIN_CODE} element={renderWithSuspense(Login)} />
          <Route
            path={PATHS.GOOGLE_SIGNUP}
            element={renderWithSuspense(Login)}
          />

          <Route path={PATHS.SIGN_UP} element={renderWithSuspense(SignUp)} />
          <Route
            path={PATHS.SIGN_UP_CODE}
            element={renderWithSuspense(SignUp)}
          />
          <Route
            path={PATHS.FORGOT_PASSWORD}
            element={renderWithSuspense(ForgotPassword)}
          />
        </Route>

        <Route element={renderWithSuspense(RequireAuth)}>
          {process.env.REACT_APP_ENV !== "production" ? (
            <Route
              path="/components-test"
              element={renderWithSuspense(ComponentsTestPage)}
            />
          ) : null}
          <Route path={PATHS.HOME} element={renderWithSuspense(Home)}>
            <Route path={PATHS.POST_ID} element={renderWithSuspense(Post)} />
          </Route>
          <Route path={PATHS.DISCOVER} element={renderWithSuspense(Discover)} />
          <Route path="/complete-profile" element={renderWithSuspense(Kyc)} />
          <Route
            path={PATHS.EMAIL_VERIFICATION}
            element={renderWithSuspense(EmailVerification)}
          />
          <Route
            path="/card/:id"
            element={renderWithSuspense(RedirectToPass)}
          />
          <Route path={PATHS.CARD_ID} element={renderWithSuspense(Card)} />
          <Route path={PATHS.DROPS} element={renderWithSuspense(Drops)} />
          <Route path={PATHS.DROPS_TAB} element={renderWithSuspense(Drops)} />
          <Route
            path={PATHS.LIMITED_EDITION_CARDS}
            element={renderWithSuspense(LimitedEdition)}
          />
          <Route path={PATHS.PROFILE} element={renderWithSuspense(NewProfile)}>
            <Route path={PATHS.POST_ID} element={renderWithSuspense(Post)} />
          </Route>
          <Route path={PATHS.SETTINGS} element={renderWithSuspense(Settings)}>
            <Route
              path={PATHS.SETTINGS_ACCOUNTS_USERNAME}
              element={renderWithSuspense(Username)}
            />
            <Route
              path={PATHS.SETTINGS_ACCOUNTS_PERSONAL_INFORMATION}
              element={renderWithSuspense(PersonalInformation)}
            />
            <Route
              path={PATHS.SETTINGS_ACCOUNTS_EMAIL}
              element={renderWithSuspense(SettingsAccountEmail)}
            />
            <Route
              path={PATHS.SETTINGS_ACCOUNTS_BLOCKED}
              element={renderWithSuspense(BlockedAccounts)}
            />

            <Route
              path={PATHS.SETTINGS_RESOURCES_HELP}
              element={renderWithSuspense(Help)}
            />
            <Route
              path={PATHS.SETTINGS_RESOURCES_POLICIES}
              element={renderWithSuspense(Policies)}
            />
            <Route
              path={PATHS.SETTINGS_RESOURCES_DATA_MANAGEMENT}
              element={renderWithSuspense(DataManagement)}
            />

            {/* <Route
              path={PATHS.SETTINGS_SECURITY_ACTIVITY_HISTORY}
              element={renderWithSuspense(ActivityHistory)}
            /> */}
            <Route
              path={PATHS.SETTINGS_SECURITY_RESET_PASSWORD}
              element={renderWithSuspense(ResetPassword)}
            />
            <Route
              path={PATHS.SETTINGS_SECURITY_TWO_FACTOR_AUTHENTICATION}
              element={renderWithSuspense(MFA)}
            />
            <Route
              path={PATHS.SETTINGS_SECURITY_ACTIVITY_TRANSACTIONS}
              element={renderWithSuspense(SettingsTransactions)}
            />

            <Route
              path="subscription"
              element={renderWithSuspense(Subscription)}
            />
            <Route
              path={PATHS.SETTINGS_EMAIL_NOTIFICATIONS}
              element={renderWithSuspense(EmailNotificationsSettings)}
            />
            <Route path="help/faq" element={renderWithSuspense(Faq)} />
          </Route>
          <Route
            path={PATHS.NOTIFICATIONS}
            element={renderWithSuspense(Notifications)}
          />
          <Route
            path="/transactions"
            element={renderWithSuspense(Transactions)}
          />
          <Route
            path={PATHS.DROPS_ID_BUY}
            element={renderWithSuspense(DropBuy)}
          />
          <Route
            path={PATHS.DROPS_ID_BUY_SUCCESS}
            element={renderWithSuspense(DropBuySuccess)}
          />
          <Route
            path={PATHS.DROPS_ID_SUCCESS}
            element={renderWithSuspense(DropBuySuccess)}
          />
          <Route
            path="/payment/accounts"
            element={renderWithSuspense(SelectPaymentMethod)}
          />
          <Route path="/referrals" element={renderWithSuspense(Referrals)} />
          <Route
            path={PATHS.REFERRALS_CREATORS}
            element={renderWithSuspense(ReferralsInfluencers)}
          />
          <Route path={PATHS.CHALLENGES} element={renderWithSuspense(Earn)} />
          <Route
            path={PATHS.CHALLENGES_ID}
            element={renderWithSuspense(NewChallengeDetails)}
          />
          <Route
            path={PATHS.CHALLENGES_CATEGORY}
            element={renderWithSuspense(ChallengeCategory)}
          />
          <Route path={PATHS.CLUBS} element={renderWithSuspense(Clubs)} />
          <Route path={PATHS.CLUBS_ID} element={renderWithSuspense(Clubs)} />
          <Route path="/add-card" element={renderWithSuspense(AddCard)} />
          <Route
            path="/add-bank-account/type"
            element={renderWithSuspense(SelectBankType)}
          />
          <Route
            path="/add-bank-account"
            element={renderWithSuspense(AddBankAccount)}
          />
          <Route
            path="/add-bank-account/bank-address"
            element={renderWithSuspense(BankAddress)}
          />
          <Route
            path="/add-card/billing-address"
            element={renderWithSuspense(BillingAddress)}
          />
          <Route
            path={PATHS.BLOCKED}
            element={renderWithSuspense(UserBlocked)}
          />
          <Route path="/rewards" element={renderWithSuspense(Rewards)} />
          <Route
            path={PATHS.FOLLOWERS}
            element={renderWithSuspense(InfluencerFollowers)}
          />
          <Route
            path={PATHS.MEETING}
            element={renderWithSuspense(MeetingRoom)}
          />
          <Route
            path={PATHS.MEETING_ID}
            element={renderWithSuspense(MeetingRoom)}
          />
          <Route
            path={PATHS.CLUB_CHALLENGES_ID}
            element={renderWithSuspense(ClubChallenges)}
          />
        </Route>
        <Route
          path={PATHS.DISCOVER_INFLUENCER_ID}
          element={renderWithSuspense(Influencer)}
        >
          <Route path={PATHS.TAB_ID} element={null} />
          <Route element={renderWithSuspense(RequireAuth)}>
            <Route path={PATHS.POST_ID} element={renderWithSuspense(Post)} />
          </Route>
        </Route>
        <Route element={renderWithSuspense(CheckInfluencerHandle)}>
          <Route path={PATHS.USERNAME} element={renderWithSuspense(Influencer)}>
            <Route path={PATHS.TAB_ID} element={null} />
            <Route path={PATHS.POST_ID} element={renderWithSuspense(Post)} />
          </Route>
          <Route
            path={PATHS.USERNAME_FOLLOWERS}
            element={renderWithSuspense(InfluencerFollowers)}
          />
        </Route>
        <Route path="*" element={renderWithSuspense(NoMatch)} />
        <Route path="/404" element={renderWithSuspense(NoMatch)} />
        <Route path="/500" element={renderWithSuspense(Error500)} />
        <Route path="/503" element={renderWithSuspense(Error503)} />
        <Route
          path={PATHS.PAYMENTS_RETURN}
          element={renderWithSuspense(StripeOnboardingReturnUrl)}
        />
        <Route
          path={PATHS.PAYMENTS_REFRESH}
          element={renderWithSuspense(StripeOnboardingRefreshUrl)}
        />
      </Routes>
    </Suspense>
  );
}

export default Router;
