import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setError,
  setLoader,
  setPayments3ds,
  setSnackbar,
} from "redux/appSlice";
import {
  fetchCard,
  fetchCardTokens,
  updateRewardOrderStatus,
} from "redux/cardSlice";
import {
  fetchChallengeDetails,
  fetchChallenges,
  updateChallenge,
} from "redux/challengesSlice";
import { addRewardMessage, fetchDrop, setCheckoutUrl } from "redux/dropsSlice";
import { fetchEditionMarketStatus } from "redux/editionSlice";
import {
  addNewMessage,
  markAllUnseenMessagesAsSeen,
  setDeletedChatId,
  setNewChat,
  updateChatCounterUnreadMessages,
  updateGlobalCounterUnreadMessages,
} from "redux/messengerSlice";
import { fetchNotificationsStatus } from "redux/notificationsSlice";
import { fetchRewards } from "redux/rewardsSlice";
import { updateUserMe } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import {
  getIdToken,
  isAuthenticated as getIsAuthenticated,
} from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";

import {
  ANALYTICS_EVENTS,
  DROP_INITIAL_PRICE,
  EVENTS,
  ORDER_TYPES,
  PATHS,
  PAYMENT_TYPE_CREDIT_CARD,
  REWARD_ORDER_STATUS,
} from "../constants";
import { NOTIFICATION_TYPE } from "../constants/notifications";
// import { toggleSeparateModal } from "../redux/modalSlice";

const PusherModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = getIsAuthenticated();
  const idToken = getIdToken();

  const { t } = useContext(LocalizationContext);

  const subscribe = (pusher) => {
    pusher.user.bind(EVENTS.NEW_NOTIFICATION, (data) => {
      if (data?.type === NOTIFICATION_TYPE.ADD_FUNDS_PROCESSED) {
        Analytics.page(window.location.pathname, {
          event: ANALYTICS_EVENTS.ADD_FUNDS,
          step: data.paymentType === PAYMENT_TYPE_CREDIT_CARD ? "2" : "3",
          paymentType: data.paymentType,
        });
      }
      dispatch(fetchNotificationsStatus());
    });

    pusher.user.bind(EVENTS.WALLET, (data) => {
      // dispatch(fetchUserWallet());
    });
    pusher.user.bind(EVENTS.SELL_ORDER_EXECUTED, () => {
      // dispatch(fetchUserWallet());
    });
    pusher.user.bind(EVENTS.BUY_ORDER_EXECUTED, () => {
      // dispatch(fetchUserWallet());
    });
    pusher.user.bind(EVENTS.CHALLENGE_STAGE_COMPLETED, (data) => {
      // dispatch(
      //   toggleSeparateModal({
      //     type: "challengeSuccess",
      //     params: {
      //       isOpen: false,
      //     },
      //   })
      // );
      // dispatch(
      //   toggleSeparateModal({
      //     type: "challengeSuccess",
      //     params: {
      //       isOpen: true,
      //       data,
      //     },
      //   })
      // );
      dispatch(fetchRewards({ offset: 0, limit: 20 }));
      if (
        window.location.pathname === PATHS.CHALLENGES ||
        window.location.pathname === PATHS.HOME
      ) {
        dispatch(fetchChallenges());
      }
      if (window.location.pathname.includes(`${PATHS.CHALLENGES}/`)) {
        dispatch(fetchChallengeDetails({ id: data.challengeId }));
      }
    });
    pusher.user.bind(EVENTS.REWARD_BALANCE, (data) => {
      dispatch(updateUserMe({ rewardPoints: data?.rewardPoints }));
    });
    pusher.user.bind(
      EVENTS.DROP_BUY_SUCCESS,
      ({ price, cardId, influencerName, paymentType }) => {
        dispatch(
          fetchEditionMarketStatus({ id: cardId, type: ORDER_TYPES.sell })
        );
        dispatch(fetchCard({ id: cardId }));
        dispatch(fetchDrop(cardId));
        dispatch(fetchCardTokens({ params: { cardId } }));
        dispatch(setLoader(false));
        dispatch(
          setSnackbar({
            open: true,
            message: t("notifications.paymentConfirmed"),
          })
        );
        Analytics.track(ANALYTICS_EVENTS.PURCHASE, {
          value: price,
          payment_type: paymentType,
          items: [
            {
              id: cardId,
              price,
              celebrity: influencerName,
              initial_price: DROP_INITIAL_PRICE,
              quantity: 1,
            },
          ],
        });
        dispatch(setCheckoutUrl(null));
      }
    );
    pusher.user.bind(EVENTS.DROP_BUY_FAILED, ({ cardId }) => {
      dispatch(setLoader(false));
      Analytics.track(ANALYTICS_EVENTS.PAYMENT_FAILED, {
        value: "payment failed",
      });
      dispatch(
        setError({
          open: true,
          title: t("notifications.paymentFailedTitle"),
        })
      );
      navigate(PATHS.DROPS_ID_BUY.replace(":id", cardId));
    });
    pusher.user.bind(EVENTS.BUY_ORDER_FAILED, () => {
      dispatch(setLoader(false));
      dispatch(
        setError({
          open: true,
          title: t("notifications.paymentFailedTitle"),
        })
      );
    });
    pusher.user.bind(EVENTS.PAYMENT_3DS_VERIFICATION, (data) => {
      if (data?.redirectUrl) {
        dispatch(setLoader(false));
        dispatch(setPayments3ds(data.redirectUrl));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS, (data) => {
      dispatch(addNewMessage(data));
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_UNREAD_COUNT, (data) => {
      if (data.chatId) {
        dispatch(updateChatCounterUnreadMessages(data));
      } else {
        dispatch(updateGlobalCounterUnreadMessages(data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_MESSAGE_SEEN, (data) => {
      if (data.chatId) {
        dispatch(markAllUnseenMessagesAsSeen(data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_DELETED, (data) => {
      if (data.chatId) {
        dispatch(setDeletedChatId(data.chatId));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_DELETED_RESTORE, (res) => {
      if (res?.data) {
        dispatch(setNewChat(res.data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.REWARD_MESSAGING, (res) => {
      if (res?.data?.message) {
        dispatch(
          addRewardMessage({
            messageContent: res.data.message,
            id: Date.now(),
            date: Date.now(),
            isIncoming: true,
            rewardOrderId: res.rewardOrderId,
          })
        );
        dispatch(
          updateRewardOrderStatus({
            ...res,
            status: REWARD_ORDER_STATUS.PENDING_REPLY,
          })
        );
      }
    });
    PusherService.pusher.user.bind(EVENTS.REWARD_DELIVERED, (res) => {
      dispatch(updateRewardOrderStatus(res));
    });
    PusherService.pusher.user.bind(EVENTS.CLUB_CHALLENGES, (res) => {
      if (res?.data) {
        dispatch(updateChallenge(res.data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHALLENGES, (res) => {
      if (res?.data) {
        dispatch(updateChallenge(res.data));
      }
    });
  };

  const unsubscribe = (pusher) => pusher.unbind_global();

  const pusherRestart = () => {
    if (PusherService.pusher) {
      PusherService.disconnect();
      unsubscribe(PusherService.pusher);
    }

    if (isAuthenticated) {
      PusherService.signin();
    } else {
      PusherService.init();
    }

    PusherService.pusher.connection.bind("connected", () => {
      if (isAuthenticated) {
        subscribe(PusherService.pusher);
      }
    });
  };

  const pusherReset = () => {
    unsubscribe(PusherService.pusher);
    PusherService.disconnect();
  };

  useEffect(() => {
    return () => {
      pusherReset();
    };
  }, []);

  useEffect(() => {
    pusherRestart();
  }, [isAuthenticated, idToken]);

  return null;
};

export default PusherModule;
