import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONNECTIONS_TABS, ORDER_TYPES, TUTORIALS_PROPS } from "constants/";
import { isEmpty } from "lodash";
import {
  addBankAccount,
  addCard,
  getPGPKey,
  withdrawFunds,
} from "services/api/paymentsApi";
import { logout as clearUserData } from "services/auth.service";
import PusherService from "services/Pusher.service";
import { cancelPendingTransaction, getTransactions } from "services/tradingApi";
import * as USER_API from "services/userApi";

export const fetchUser = createAsyncThunk(
  "users/profile",
  async (_, { rejectWithValue }) => {
    try {
      return await USER_API.getUserProfile();
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchUserMe = createAsyncThunk(
  "users/me",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.getUser();
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchUserZendeskJwt = createAsyncThunk(
  "users/zendeskJwt",
  async () => {
    const response = await USER_API.getUserZendeskJWT();
    return response;
  }
);

export const fetchUserEditions = createAsyncThunk(
  "users/cards",
  async (params) => {
    const response = await USER_API.getUserCard(params);
    return response.data;
  }
);

export const fetchUserRank = createAsyncThunk("users/rank", async (params) => {
  const response = await USER_API.getUserRankAndGems(params);
  return response;
});

export const fetchUserWallet = createAsyncThunk("users/wallet", async () => {
  const response = await USER_API.getUserWallet();
  return response.data;
});

export const updateTrophyCard = createAsyncThunk(
  "users/update/trophyCard",
  async (params) => {
    const response = await USER_API.updateUserTrophyCard(params);
    return response.data;
  }
);

export const fetchUserTransactions = createAsyncThunk(
  "users/transactions",
  async (params) => {
    const response = await getTransactions(params);
    return response.data;
  }
);

export const uploadPhoto = createAsyncThunk(
  "users/upload/photo",
  async ({ image }) => {
    try {
      const s3Response = await USER_API.generateS3Url();

      await fetch(s3Response.data.presignedUploadUrl, {
        method: "PUT",
        body: image,
      });

      const params = {
        userPicOriginalUrl: s3Response.data.imageUrl,
        userPicCroppedUrl: s3Response.data.imageUrl,
      };

      const response = await USER_API.updateUser(params);

      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const fetchKycStatus = createAsyncThunk("kyc/status", async () => {
  const response = await USER_API.getKycStatus();
  return response.data;
});

export const updateKycStatus = createAsyncThunk(
  "kyc/status/update",
  async () => {
    const response = await USER_API.checkEidCheck();
    return response.data;
  }
);

export const verifyEmail = createAsyncThunk(
  "email/verify",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.sendVerifyEmail(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const verifyCode = createAsyncThunk("code/verify", async (params) => {
  const response = await USER_API.sendVerifyCode(params);
  return response;
});

export const updateUserProfile = createAsyncThunk(
  "user/update/profile",
  async (params, { rejectWithValue }) => {
    try {
      const response = await USER_API.updateUser(params);
      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchTransfers = createAsyncThunk(
  "users/settings/transfers",
  async (params) => {
    const response = await USER_API.getTransfers(params);
    return response.data;
  }
);

export const fetchEmailNotificationSettings = createAsyncThunk(
  "users/settings/email-notifications",
  async () => {
    const response = await USER_API.getEmailNotificationSettings();
    return response.data;
  }
);

export const updateEmailNotificationSettings = createAsyncThunk(
  "users/settings/email-notifications/update",
  async (params) => {
    await USER_API.updateEmailNotificationSettings(params);
    return params;
  }
);

export const fetchUserReferrals = createAsyncThunk(
  "users/referrals",
  async () => {
    const response = await USER_API.getUserReferrals();
    return response.data;
  }
);

export const fetchUserReferralsInfluencers = createAsyncThunk(
  "users/referrals/influencers",
  async () => {
    const response = await USER_API.getUserReferralsInfluencers();
    return response.data;
  }
);

export const searchUser = createAsyncThunk("users/search", async (params) => {
  const response = await USER_API.searchUserByUserName(params);
  return response.data;
});

export const fetchPublicUser = createAsyncThunk(
  "users/publicUser",
  async (id) => {
    const response = await USER_API.getPublicUser(id);
    return response.data;
  }
);

export const cancelTransaction = createAsyncThunk(
  "users/cancel/transaction",
  async (orderId) => {
    const response = await cancelPendingTransaction(orderId);
    return response.data;
  }
);

export const updateCard = createAsyncThunk(
  "users/cards/update",
  async ({ cardId, props }) => {
    const response = await USER_API.updateCardProps(cardId, props);
    return response;
  }
);

export const fetchUserConnections = createAsyncThunk(
  "users/connections",
  async (params) => {
    const response = await USER_API.getUserConnections(params);
    return response.data;
  }
);

export const sendConnectionRequest = createAsyncThunk(
  "users/connections/sendRequest",
  async (userId) => {
    const response = await USER_API.sendFriendRequest(userId);
    return response.data;
  }
);

export const acceptFriendRequest = createAsyncThunk(
  "users/connections/accept",
  async (connectionId) => {
    const response = await USER_API.acceptUserFriendRequest(connectionId);
    return response?.data;
  }
);

export const rejectFriendRequest = createAsyncThunk(
  "users/connections/reject",
  async (connectionId) => {
    const response = await USER_API.rejectUserFriendRequest(connectionId);
    return response?.data;
  }
);

export const unfriendUser = createAsyncThunk(
  "users/connections/remove",
  async (userId) => {
    const response = await USER_API.removeUserConnection(userId);
    response.userId = userId;
    return response;
  }
);

export const fetchUserFriendRequests = createAsyncThunk(
  "users/connections/requests",
  async (params) => {
    const response = await USER_API.getFriendRequests(params);
    return response.data;
  }
);

export const fetchFriendRequestsIndicator = createAsyncThunk(
  "users/connections/requests/getIndicator",
  async () => {
    const response = await USER_API.getFriendRequestsIndicator();
    return response.data;
  }
);

export const hideFriendRequestsIndicator = createAsyncThunk(
  "users/connections/requests/hideIndicator",
  async () => {
    const response = await USER_API.setFriendRequestsIndicator();
    return response.data;
  }
);

export const followInfluencer = createAsyncThunk(
  "influencer/follow",
  async (params, { rejectWithValue }) => {
    try {
      const response = await USER_API.followInfluencer(params);
      return response;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const unFollowInfluencer = createAsyncThunk(
  "influencer/unfollow",
  async (params, { rejectWithValue }) => {
    try {
      const response = await USER_API.unFollowInfluencer(params);

      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchInfluencerFollowers = createAsyncThunk(
  "influencer/followers",
  async (params) => {
    const response = await USER_API.getInfluencerFollowers(params);
    return response;
  }
);

export const fetchMyFollows = createAsyncThunk(
  "user/follows",
  async (params) => {
    const response = await USER_API.getUsersCommunities(params);
    return response;
  }
);
export const deleteYourAccount = createAsyncThunk(
  "user/account/delete",
  async () => {
    const response = await USER_API.deleteYourAccount();
    return response;
  }
);

export const rejectDeleteYourAccount = createAsyncThunk(
  "user/account/delete/reject",
  async () => {
    const response = await USER_API.rejectDeleteYourAccount();
    return response;
  }
);

export const fetchFriendsById = createAsyncThunk(
  "user/public/friends",
  async (params) => {
    const response = await USER_API.getUserFriendsById(params);
    return response;
  }
);
export const fetchFollowingByUserId = createAsyncThunk(
  "user/public/followers",
  async (params) => {
    const response = await USER_API.getFollowingByUserId(params);
    return response;
  }
);
export const externalWallet = createAsyncThunk(
  "user/external/wallet",
  async (params) => {
    const response = await USER_API.sendExternalWallet(params);
    return response;
  }
);
export const withdrawCards = createAsyncThunk(
  "user/cards/withdraw",
  async (params) => {
    const response = await USER_API.withdrawCards(params);
    return response;
  }
);

export const fetchBlacklist = createAsyncThunk("user/blacklist", async () => {
  const response = await USER_API.getBlacklist();
  return response;
});

export const blockUser = createAsyncThunk(
  "user/blacklist/add",
  async (userId) => {
    const response = await USER_API.blockUserById(userId);
    return response;
  }
);

export const unblockUser = createAsyncThunk(
  "user/blacklist/remove",
  async (userId) => {
    const response = await USER_API.unblockUserById(userId);
    return response;
  }
);
export const fetchPaymentKey = createAsyncThunk(
  "user/payment/key",
  async () => {
    const response = await getPGPKey();
    return response;
  }
);

export const addPaymentCard = createAsyncThunk(
  "user/payment/add-card",
  async (params, { rejectWithValue }) => {
    try {
      const response = await addCard(params);
      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const addPaymentBankAccount = createAsyncThunk(
  "user/payment/add-bank-account",
  async (params, { rejectWithValue }) => {
    try {
      const response = await addBankAccount(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

export const withdraw = createAsyncThunk(
  "user/payment/withdraw",
  async (params) => {
    try {
      const response = await withdrawFunds(params);
      return response;
    } catch (e) {
      console.log(e);
    }
  }
);

export const updateZendeskUserInfo = createAsyncThunk(
  "user/payment/add-card",
  async (params) => {
    const response = await USER_API.updateZendeskUserInfo(params);
    return response;
  }
);

export const fetchUserDevices = createAsyncThunk(
  "user/devices/get",
  async () => {
    const response = await USER_API.getUserDevices();
    return response;
  }
);

export const logoutAllDevices = createAsyncThunk(
  "user/devices/logout",
  async (params) => await USER_API.logoutAllUserDevices(params)
);

export const fetchBadges = createAsyncThunk("user/badges", async (params) => {
  const response = await USER_API.getBadges(params);
  return response;
});

export const verifyPhoneNumber = createAsyncThunk(
  "user/settings/verify-phone",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.verifyPhone(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getMFACode = createAsyncThunk(
  "user/settings/get-MFA-code",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.generateMFACode(params);
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

const initialState = {
  editions: {
    loading: false,
    entities: [],
  },
  rank: {
    loading: false,
    entities: {
      amount: undefined, // gems
      rank: undefined, // current rank
    },
  },
  transactions: {
    loading: false,
    entities: [],
    isLastBuyPage: false,
    isLastSellPage: false,
  },
  wallet: {
    loading: false,
    entities: {},
  },
  me: {
    loading: false,
    entities: {},
  },
  transfers: {
    loading: false,
    entities: [],
    hasMore: true,
  },
  status: "idle",
  loading: false,
  entities: {
    seenTutorials: {
      [TUTORIALS_PROPS.CREATORS_TUTORIAL_1]: true,
      [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: true,
      [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
      [TUTORIALS_PROPS.GROUPS_TUTORIAL_1]: true,
      [TUTORIALS_PROPS.GROUP_ID_TUTORIAL_1]: true,
      [TUTORIALS_PROPS.MARKETPLACE_TUTORIAL_1]: true,
    },
  },
  //TODO::this kyc object seems to be retundant - remove it
  kyc: {
    loading: false,
    entities: {},
  },
  referrals: {
    loading: false,
    entities: null,
    influencers: {
      loading: false,
      entities: [],
    },
  },
  connections: {
    loading: false,
    entities: {
      total: 0,
      rows: [],
      isLastPage: true,
    },
    whoPosted: {
      total: 0,
      rows: [],
    },
    requests: {
      loading: false,
      entities: [],
      isLastPage: false,
      showIndicator: false,
    },
    friends: {
      rows: [],
      loading: false,
      total: 0,
    },
    activeTab: CONNECTIONS_TABS.friends,
  },
  search: {
    loading: false,
    entities: {},
  },
  publicUser: {
    loading: false,
    entities: {},
  },
  followers: {
    influencers: {
      rows: [],
      total: 0,
    },
    loading: false,
    my: {
      loading: false,
      entities: [],
      isLastPage: false,
    },
  },
  following: {
    loading: false,
    entities: [],
    isLastPage: false,
  },
  blacklist: {
    loading: false,
    entities: [],
  },
  devices: {
    loading: false,
    entities: [],
  },
  badges: {
    entities: [],
    total: 0,
    isLastPage: true,
  },
  emailNotifications: {
    loading: false,
    entities: {},
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleCardBarter(state, action) {
      const cardId = action.payload.cardId;
      const cardIndex = state.editions.entities.findIndex(
        (c) => c.id === cardId
      );
      state.editions.entities[cardIndex].isSwappable =
        action.payload.props.isSwappable;
    },
    logout(state, action) {
      PusherService.disconnect();
      clearUserData();
      state = initialState;
    },
    changeConnectionsTab(state, action) {
      state.connections.activeTab = action.payload;
    },
    clearPublicUser(state) {
      state.publicUser.entities = {};
    },
    clearPublicFollowers(state, action) {
      state.following.entities = [];
    },
    clearInfluencerFollower(state, action) {
      state.followers.influencers = initialState.followers.influencers;
    },
    updateUserMe(state, action) {
      state.me.entities = { ...state.me.entities, ...action.payload };
    },
    updateStaticUserProfile(state, action) {
      state.entities = {
        ...state.entities,
        ...action.payload,
      };
    },
    updateUserEntities(state, action) {
      state.entities = {
        ...state.entities,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.entities = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
      })
      .addCase(fetchUserMe.pending, (state, action) => {
        state.me.loading = true;
      })
      .addCase(fetchUserMe.fulfilled, (state, action) => {
        state.me.loading = false;
        state.me.entities = action.payload;
      })
      .addCase(fetchUserMe.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchUserEditions.pending, (state, action) => {
        state.editions.loading = true;
      })
      .addCase(fetchUserEditions.rejected, (state, action) => {
        state.editions.loading = false;
      })
      .addCase(fetchUserEditions.fulfilled, (state, action) => {
        state.editions.loading = false;
        state.editions.entities = action.payload;
      })
      .addCase(fetchUserRank.pending, (state, action) => {
        state.rank.loading = true;
      })
      .addCase(fetchUserRank.rejected, (state, action) => {
        state.rank.loading = false;
      })
      .addCase(fetchUserRank.fulfilled, (state, action) => {
        state.rank.loading = false;
        state.rank.entities = action.payload;
      })
      .addCase(fetchUserWallet.pending, (state, action) => {
        state.wallet.loading = true;
      })
      .addCase(fetchUserWallet.fulfilled, (state, action) => {
        state.wallet.loading = false;
        state.wallet.entities = action.payload;
      })
      .addCase(fetchUserWallet.rejected, (state, action) => {
        state.wallet.loading = false;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.wallet.loading = false;
        state.entities = action.payload;
      })
      .addCase(fetchKycStatus.pending, (state, action) => {
        state.kyc.loading = true;
      })
      .addCase(fetchKycStatus.fulfilled, (state, action) => {
        state.kyc.loading = false;
        state.kyc.entities = action.payload;
      })
      .addCase(fetchKycStatus.rejected, (state, action) => {
        state.kyc.loading = false;
      })
      .addCase(fetchUserTransactions.pending, (state, action) => {
        state.transactions.loading = true;
      })
      .addCase(fetchUserTransactions.rejected, (state, action) => {
        state.transactions.loading = false;
        state.transactions.isLastPage = true;
      })
      .addCase(fetchUserTransactions.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.entities = action.payload;

        const buyTransactions = action.payload.executedTransactions?.filter(
          (t) => t.type === ORDER_TYPES.buy
        );
        const sellTransactions = action.payload.executedTransactions?.filter(
          (t) => t.type === ORDER_TYPES.sell
        );

        state.transactions.isLastBuyPage =
          buyTransactions?.length < action?.meta?.arg?.limit;
        state.transactions.isLastSellPage =
          sellTransactions?.length < action?.meta?.arg?.limit;
      })
      .addCase(fetchTransfers.pending, (state, action) => {
        state.transfers.loading = true;
      })
      .addCase(fetchTransfers.rejected, (state, action) => {
        state.transfers.loading = false;
      })
      .addCase(fetchTransfers.fulfilled, (state, action) => {
        state.transfers.loading = false;
        state.transfers.entities = [
          ...state.transfers.entities,
          ...action.payload,
        ];
        state.transfers.hasMore =
          action.payload?.length >= action?.meta?.arg?.limit;
      })
      .addCase(fetchEmailNotificationSettings.pending, (state) => {
        state.emailNotifications.loading = true;
      })
      .addCase(fetchEmailNotificationSettings.rejected, (state) => {
        state.emailNotifications.loading = false;
      })
      .addCase(fetchEmailNotificationSettings.fulfilled, (state, action) => {
        state.emailNotifications.loading = false;
        const { payload } = action;
        if (payload) {
          state.emailNotifications.entities = action.payload;
        }
      })
      .addCase(updateEmailNotificationSettings.fulfilled, (state, action) => {
        state.emailNotifications.entities = action.payload;
      })
      .addCase(fetchUserReferrals.pending, (state, action) => {
        state.referrals.loading = true;
      })
      .addCase(fetchUserReferrals.rejected, (state, action) => {
        state.referrals.loading = false;
      })
      .addCase(fetchUserReferrals.fulfilled, (state, action) => {
        state.referrals.loading = false;
        state.referrals.entities = action.payload;
      })
      .addCase(fetchUserReferralsInfluencers.pending, (state, action) => {
        state.referrals.influencers.loading = true;
      })
      .addCase(fetchUserReferralsInfluencers.rejected, (state, action) => {
        state.referrals.influencers.loading = false;
      })
      .addCase(fetchUserReferralsInfluencers.fulfilled, (state, action) => {
        state.referrals.influencers.loading = false;
        state.referrals.influencers.entities = action.payload;
      })
      .addCase(searchUser.pending, (state, action) => {
        state.search.loading = true;
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.search.loading = false;
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.search.loading = false;
        state.search.entities = action.payload;
      })
      .addCase(fetchPublicUser.pending, (state, action) => {
        state.publicUser.loading = true;
      })
      .addCase(fetchPublicUser.rejected, (state, action) => {
        state.publicUser.loading = false;
      })
      .addCase(fetchPublicUser.fulfilled, (state, action) => {
        state.publicUser.loading = false;
        state.publicUser.entities = action.payload || {};
      })
      .addCase(fetchInfluencerFollowers.pending, (state, action) => {
        state.followers.loading = true;
      })
      .addCase(fetchInfluencerFollowers.rejected, (state, action) => {
        state.followers.loading = false;
      })
      .addCase(fetchInfluencerFollowers.fulfilled, (state, action) => {
        const newRows = action.payload?.rows || [];
        state.followers.influencers.rows = [
          ...state.followers.influencers.rows,
          ...newRows,
        ];
        state.followers.influencers.total = action.payload?.total;

        state.followers.loading = false;
      })

      .addCase(fetchMyFollows.pending, (state, action) => {
        state.followers.my.loading = true;
      })
      .addCase(fetchMyFollows.rejected, (state, action) => {
        state.followers.my.loading = false;
      })
      .addCase(fetchMyFollows.fulfilled, (state, action) => {
        state.followers.my.loading = false;
        state.followers.my.entities = action.payload;

        state.followers.my.isLastPage =
          action.payload?.length < action?.meta?.arg?.limit;
      })
      .addCase(unFollowInfluencer.pending, (state, action) => {
        state.followers.loading = true;
      })
      .addCase(unFollowInfluencer.rejected, (state, action) => {
        state.followers.loading = false;
      })
      .addCase(unFollowInfluencer.fulfilled, (state, action) => {
        state.followers.loading = false;
      })
      .addCase(followInfluencer.rejected, (state, action) => {
        state.followers.loading = false;
      })
      .addCase(followInfluencer.pending, (state, action) => {
        state.followers.loading = true;
      })
      .addCase(followInfluencer.fulfilled, (state, action) => {
        state.followers.loading = false;
      })
      .addCase(fetchUserConnections.pending, (state, action) => {
        state.connections.loading = true;
      })
      .addCase(fetchUserConnections.rejected, (state, action) => {
        state.connections.loading = false;
      })
      .addCase(fetchUserConnections.fulfilled, (state, action) => {
        const { type, page } = action?.meta?.arg || {};
        state.connections.loading = false;
        if (type === "whoPosted") {
          state.connections.whoPosted.rows = action.payload.rows;
        } else {
          state.connections.entities = {
            total: action.payload.total,
            rows:
              page > 1
                ? [...state.connections.entities.rows, ...action.payload.rows]
                : action.payload.rows,
          };

          state.connections.entities.isLastPage =
            state.connections.entities.rows.length === action.payload.total ||
            !action.payload.rows.length;
        }
      })
      .addCase(fetchUserFriendRequests.pending, (state, action) => {
        state.connections.requests.loading = true;
      })
      .addCase(fetchUserFriendRequests.rejected, (state, action) => {
        state.connections.requests.loading = false;
      })
      .addCase(fetchUserFriendRequests.fulfilled, (state, action) => {
        state.connections.requests.loading = false;
        state.connections.requests.entities = action.payload;

        state.connections.requests.isLastPage =
          action.payload?.length < action?.meta?.arg?.limit;
      })
      .addCase(rejectFriendRequest.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.connections.requests.entities =
          state.connections.requests.entities.filter((item) => item.id !== id);
      })
      .addCase(acceptFriendRequest.fulfilled, (state, action) => {
        const id = action.meta.arg;
        let foundItem = null;
        state.connections.requests.entities =
          state.connections.requests.entities.reduce((acc, element) => {
            if (element.id === id) {
              foundItem = element;
            } else {
              acc.push(element);
            }
            return acc;
          }, []);

        state.connections.entities.total = state.connections.entities.total + 1;
        state.connections.entities.rows = [
          foundItem,
          ...state.connections.entities.rows,
        ];
      })
      .addCase(fetchFriendRequestsIndicator.fulfilled, (state, action) => {
        state.connections.requests.showIndicator = !!action?.payload?.indicator;
      })
      .addCase(fetchFriendsById.pending, (state) => {
        state.connections.friends.loading = true;
      })
      .addCase(fetchFriendsById.rejected, (state) => {
        state.connections.friends.loading = false;
      })
      .addCase(fetchFriendsById.fulfilled, (state, action) => {
        const { page } = action?.meta?.arg || {};
        state.connections.friends.total = action.payload.total;
        state.connections.friends.loading = false;
        state.connections.friends.rows =
          page > 1
            ? [
                ...state.connections.friends.rows,
                ...(action.payload.rows || []),
              ]
            : action.payload.rows;
      })
      .addCase(fetchFollowingByUserId.pending, (state, action) => {
        state.following.loading = true;
        state.following.entities = [];
      })
      .addCase(fetchFollowingByUserId.rejected, (state, action) => {
        state.following.loading = false;
        state.following.entities = [];
      })
      .addCase(fetchFollowingByUserId.fulfilled, (state, action) => {
        state.following.entities = action?.payload;
        state.following.loading = false;
      })
      .addCase(addPaymentBankAccount.fulfilled, (state, action) => {
        // state.connections.requests.showIndicator = false;
      })
      .addCase(hideFriendRequestsIndicator.fulfilled, (state, action) => {
        state.connections.requests.showIndicator = false;
      })
      .addCase(fetchBlacklist.pending, (state, action) => {
        state.connections.requests.loading = true;
      })
      .addCase(fetchBlacklist.rejected, (state, action) => {
        state.connections.requests.loading = false;
      })
      .addCase(fetchBlacklist.fulfilled, (state, action) => {
        state.blacklist.loading = false;
        state.blacklist.entities = action.payload.blacklist || [];
      })
      .addCase(fetchUserDevices.pending, (state, action) => {
        state.devices.loading = true;
      })
      .addCase(fetchUserDevices.rejected, (state, action) => {
        state.devices.loading = false;
      })
      .addCase(fetchUserDevices.fulfilled, (state, action) => {
        state.devices.loading = false;
        state.devices.entities = action.payload;
      })
      .addCase(logoutAllDevices.pending, (state, action) => {
        state.devices.loading = true;
      })
      .addCase(logoutAllDevices.rejected, (state, action) => {
        state.devices.loading = false;
      })
      .addCase(logoutAllDevices.fulfilled, (state, action) => {
        state.devices.loading = false;
        state.devices.entities = [];
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = {
          ...state.entities,
          ...action.payload,
        };
      })
      .addCase(verifyPhoneNumber.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyPhoneNumber.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(verifyPhoneNumber.fulfilled, (state, action) => {
        state.loading = false;
        const phoneUpdate = action?.meta?.arg?.phoneNumber
          ? { phoneNumber: action?.meta?.arg?.phoneNumber }
          : null;

        state.entities = {
          ...state.entities,
          isPhoneVerified: true,
          is2faEnabled: true,
          ...phoneUpdate,
        };
      })
      .addCase(fetchBadges.fulfilled, (state, action) => {
        const { page } = action.meta.arg;
        state.badges.total = action.payload?.total || 0;
        if (!page || page === 1) {
          state.badges.entities = action.payload?.badges;
        } else {
          state.badges.entities = [
            ...state.badges.entities,
            ...(action.payload?.badges || []),
          ];
        }
        state.badges.isLastPage =
          action.payload?.total < action?.meta?.arg?.limit;
      })
      .addCase(unfriendUser.fulfilled, (state, action) => {
        const status = action.payload.status;
        const userId = action.payload.userId;
        if (status === 201 && userId) {
          state.connections.entities.rows =
            state.connections.entities.rows.filter(
              (connection) => connection.id !== userId
            );
          state.connections.entities.total =
            state.connections.entities.total - 1;
        }
      });
  },
});

export const {
  toggleCardBarter,
  logout,
  changeConnectionsTab,
  clearPublicUser,
  clearPublicFollowers,
  clearInfluencerFollower,
  updateUserMe,
  updateStaticUserProfile,
  updateUserEntities,
} = usersSlice.actions;

export default usersSlice.reducer;
export const isWalletEmpty = (state) => isEmpty(state.users.wallet.entities);
export const defaultPaymentMethod = (state) =>
  state.users.me.entities.defaultPaymentMethod;

export const selectConnectionById = (state, userId) => {
  return state.users.connections?.entities?.rows.find(
    (connection) => connection.friend.id === userId
  );
};
