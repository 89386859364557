/* eslint-disable no-undef */
import Pusher from "pusher-js";

import { getGoogleAccessToken, getIdToken } from "./auth.service";
const ID_TOKEN = "dapple-id-token";

class PusherService {
  pusher = null;

  static init() {
    Pusher.logToConsole = true;

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "eu",
    });
  }

  static subscribe(channel) {
    if (this.pusher) {
      return this.pusher.subscribe(channel);
    }
  }

  static unsubscribe(channel) {
    return this.pusher.unsubscribe(channel);
  }

  static disconnect() {
    return this.pusher.disconnect();
  }
  static signin() {
    Pusher.logToConsole = true;
    const idToken = getIdToken();
    const googleAuthToken = getGoogleAccessToken();

    const Authorization = window.Telegram?.WebApp?.initDataUnsafe?.user?.id
      ? `Telegram ${window.Telegram.WebApp.initData}`
      : googleAuthToken
      ? `google ${googleAuthToken}`
      : `Bearer ${idToken}`;

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_ENV === "production" ? "eu" : "us3",
      userAuthentication: {
        endpoint: `${process.env.REACT_APP_API_HOST}/pusher/authenticate`,
        transport: "ajax",
        params: {},
        headers: {
          "Content-Type": "application/json",
          "ii-api-version": process.env.REACT_APP_API_VERSION,
          "ii-api-language": localStorage.getItem("ii-language"),
          Authorization,
        },
        customHandler: null,
      },
    });
    this.pusher.signin();
  }
}

export default PusherService;
