import colors from "theme/colors";

import { palette } from "../palette";

const {
  neutralWhite,
  neutralGray50,
  neutralGray100,
  neutralGray200,
  neutralGray300,
  neutralGray400,
  neutralGray500,
  neutralGray600,
  neutralGray700,
  neutralGray800,
  neutralGray900,
  neutralGray950,
  primary600,
  primary500,
  primary400,
  primary200,
  primary100,
  gradientPrimary500,
  doubleGradient,
  doubleGradientLight,
  gradientPrimary400,
  brightRed,
  errorText,
  primary,
  white,
} = colors;

export const paletteLight = {
  ...palette,
  primary600: primary600,
  themeBackground: "rgba(248, 250, 252, 0.50)",
  themeBackground2: "rgba(248, 250, 252, 0.80)",
  themeBackground3: "rgba(221, 214, 254, 0.50)",
  themeBackground4: neutralGray50,
  themeBoxShadow: "0px 4px 80px 0px rgba(0, 0, 0, 0.05)",
  themeBoxShadow2: "0px 4px 20px 0px rgba(0, 0, 0, 0.03)",
  themeBorder: neutralGray200,
  noImageBackground:
    "linear-gradient(128.02deg, rgba(173, 66, 255, 0.3) 0%, rgba(95, 137, 247, 0.3) 100%);",
  borderNeutralGray100: neutralGray100,
  whiteBackground: neutralGray50,
  textNeutralGray800: neutralGray800,
  textNeutralGray600: neutralGray600,
  textLink: neutralGray600,
  textNeutralGray500: neutralGray500,
  lightText: neutralGray500,
  doubleGradient: doubleGradient,
  lightDoubleGradient: doubleGradientLight,
  textTag: {
    text: primary600,
    background: primary200,
  },
  mainBackground: neutralGray100,
  surface: {
    main: neutralGray50,
    border: neutralGray200,
    darken: neutralGray100,
    darkenBorder: neutralGray300,
    background: neutralGray50,
    backgroundHover: primary200,
    backgroundLight: neutralWhite,
    shadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.03)",
    gradient: gradientPrimary500,
    textLight0: neutralWhite,
  },
  error: {
    main: brightRed,
    text: errorText,
  },
  main: "#F5FBFE",
  contrastText: "#FFFFFF",
  placeholder: neutralGray400,
  darkBtnHover: neutralGray100,
  disabledInputColor: neutralGray400,
  /*---------------*/
  mainTextColor: neutralGray900,
  darkTextColor: neutralGray950,
  containerBorderColor: "#DCEAF9",
  containerBackground: "#F5FBFE",
  pageLayoutOverlay: "#f1f5f9",
  pageLayoutOverlayBoxShadow: "0 4px 250px 0 rgba(0, 0, 0, 0.03) inset",
  header: {
    background: "#F5FAFF",
    boxShadow: "0px 4px 112px 0px rgba(80, 90, 169, 0.10)",
    backdropFilter: "blur(8px)",
    icon: "#4E5774",
  },
  searchBar: {
    button: "#F5FBFE",
    buttonIcon: "#4E5774",
  },
  post: {
    translationIcon: {
      fill: "#4E5774",
    },
    divider: {
      color: "rgba(78,87,116, 0.2)",
    },
    longTextShadow: "linear-gradient(0deg, #F5FBFE, transparent)",
    secondaryText: neutralGray500,
    actionsButton: {
      border: primary,
      hover:
        "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 30%, rgba(95, 137, 247, 0.10) 70%)",
      secondaryText: {
        hover: "rgba(226, 232, 240, 0.50)",
        icon: {
          stroke: "#64748B",
        },
        disbaled: neutralGray400,
      },
    },
  },
  commentsCount: {
    icon: {
      stroke: "#4E5774",
    },
  },
  reactionsInfo: {
    icon: {
      stroke: "#4E5774",
      rectFill: "#fff",
    },
  },
  tab: {
    panel: {
      background:
        "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    },
    button: {
      color: "#4E5774",
    },
    indicator: {
      background: "linear-gradient(128.02deg, #AD42FF 0%, #5F89F7 100%)",
    },
  },
  viewAll: {
    color: "#742cf3",
  },
  customTooltip: {
    background: neutralGray50,
    boxShadow:
      "0px 4px 20px 0px rgba(0, 0, 0, 0.06), 0px 4px 20px 0px rgba(0, 0, 0, 0.03)",
    border: `1px solid ${neutralGray200}`,
    arrowColor: neutralGray50,
    text: neutralGray800,
    icon: {
      path: {
        stroke: neutralGray800,
      },
    },
  },
  backBtn: {
    color: neutralGray800,
  },
  activeText: {
    background: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  badge: {
    background: "rgba(80, 90, 169, 0.25)",
    border: "rgba(80, 90, 169, 0.20)",
  },
  text: {
    secondary: "#7681A3",
  },
  profileNav: {
    paper: "white",
    border: "#7681A3",
    icons: "#001833",
    info: {
      icon: "#5F89F7",
    },
    avatar: {
      background: "#7681A3",
    },
  },
  newCard: {
    background:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)!important",
  },
  fullCard: {
    border: "#9C2CF3",
    button: {
      background:
        "linear-gradient(128deg, rgba(173, 66, 255, 0.80) 0%, rgba(95, 137, 247, 0.80) 100%)",
      color: "#FFF",
    },
  },
  cardProgress: {
    main: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
    progressBg: "rgba(115, 118, 150, 0.20)",
  },
  progress: {
    background: neutralGray200,
    color: doubleGradient,
    textColor: "#4E5774",
  },
  bottomMenu: {
    background: "#F5FAFF",
    boxShadow: "0px -4px 112px 0px rgba(80, 90, 169, 0.10)",
    item: {
      color: "#7882A1",
      colorActive: "#AD42FF",
      svg: {
        path: {
          stroke: "#7882A1",
          strokeActive: "#AD42FF",
        },
      },
      activeIndicator: {
        background: "#AD42FF",
        boxShadow: "0px 2px 4px 1px rgba(72, 141, 196, 0.25)",
      },
      activeText: {
        background: "linear-gradient(128deg, #9c2cf3 0%, #3a6ff9 100%)",
      },
    },
  },
  challenge: {
    lightColor: "#001833",
  },
  chat: {
    hoverBackground:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    hoverShadow:
      "linear-gradient(270deg, rgba(173, 66, 255, 0.10) 53.08%, rgba(95, 137, 247, 0) 100%)",
    messageContainer: {
      background: white,
      borderColor: neutralGray100,
    },
  },
  empty: {
    icon: neutralGray600,
    iconActive: primary600,
  },
  google: {
    authBackground: neutralGray100,
    color: neutralGray700,
  },
  emailBanner: {
    background: doubleGradient,
    text: "#F8FAFC",
  },
  sidebar: {
    activeItem:
      "linear-gradient(128deg, #9c2cf3 0%, #3a6ff9 100%),linear-gradient(128deg, #ad42ff 0%, #5f89f7 100%)",
    hoverBackground: "rgba(226, 232, 240, 0.5)",
    creator: {
      color: "rgba(255, 255, 255, 1)",
    },
    boxShadow: "2px 2px 8px 1px rgba(72, 141, 196, 0.25)",
  },
  scrollbar: {
    track: {
      background: neutralGray200,
    },
    thumb: {
      background: primary200,
      borderColor: primary200,
    },
  },
  accentText: gradientPrimary400,
  activeBorder: "#AD42FF",
  activeBorderGradient: "linear-gradient(to bottom right, #9c2cf3, #5F89F7)",
  activeBorderGradientMobile: "linear-gradient(to right, #9c2cf3, #5F89F7)",
  popover: {
    shadow: "rgba(0, 0, 0, 0.06)",
  },
  accent: {
    main: primary500,
    secondary: primary400,
    background: primary200,
  },
  slider: {
    dots: neutralGray200,
    arrowsBg: neutralGray50,
    boxShadow:
      "0 4px 112px 0 rgba(15, 23, 42, 0.30), 0 4px 20px 0 rgba(15, 23, 42, 0.06)",
  },
  hederaIcon: {
    color: neutralGray600,
  },
  authPage: {
    title: {
      color: neutralGray800,
    },
    subtitle: {
      color: neutralGray600,
    },
    inactiveButton: {
      color: primary600,
    },
    separator: neutralGray300,
    input: {
      borderColor: neutralGray300,
      backgroundColor: neutralGray50,
    },
    submitBtn: doubleGradient,
  },
  skeleton:
    "linear-gradient(128deg,rgba(156, 44, 243, 0.1) 30%,rgba(58, 111, 249, 0.1) 70%)",
  page: {
    home: {
      tabs: {
        fixed: {
          background: "rgba(248, 250, 252, 0.80)",
          boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.03)",
        },
      },
    },
  },
  poll: {
    background: neutralGray100,
    progress: neutralGray200,
    accent: primary500,
    text: neutralGray600,
  },
  cardFeed: {
    borderColor: neutralGray200,
    boxShadow: "0 4 20px 0 rgba(0, 0, 0, 0.03)",
    background: neutralGray50,
  },
  fileThumb: {
    fake: {
      background: "rgba(221, 214, 254, 0.50)",
    },
    preview: {
      background: "rgba(221, 214, 254, 0.5)",
    },
  },
  chatWidget: {
    message: {
      background: neutralGray200,
      backgroundIncome: neutralGray100,
      color: neutralGray800,
      borderColor: neutralGray200,
    },
    footer: {
      borderColor: neutralGray300,
    },
  },
  datePicker: {
    color: neutralGray900,
    background: neutralGray200,
    backgroundActive:
      "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%), linear-gradient(128deg, #9C2CF3 0%, #3A6FF9 100%)",
  },
  mainTitle: {
    backBtn: {
      background: neutralGray200,
      icon: neutralGray500,
    },
  },
  veve: {
    tag: {
      background: "#006FE6!important",
      color: "#FFFFFF!important",
      textTransform: "uppercase",
    },
    // tabs: {
    //   selected: {
    //     background: "none",
    //     webkitBackgroundClip: "unset",
    //     webkitTextFillColor: "unset",
    //   },
    //   indicator: "#006FE6",
    // },
    btn: {
      contained: {
        background: "linear-gradient(90deg, #158EFD 0%, #0048B4 100%)",
        hover: {
          background:
            "linear-gradient(90deg, rgba(21, 142, 253, 0.8) 0%, rgba(0, 72, 180, 0.8) 100%)",
        },
      },
      secondary: {
        color: "#006FE6",
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(90deg, rgba(21, 142, 253, 0.40) 0%, rgba(0, 72, 180, 0.40) 100%)",
        hover: {
          color: "#006FE6",
          background:
            "linear-gradient(90deg, rgba(21, 142, 253, 0.20) 0%, rgba(0, 72, 180, 0.20) 100%), #F5FAFF",
        },
        disabled: {
          background:
            "linear-gradient(90deg, rgba(21, 142, 253, 0.10) 0%, rgba(0, 72, 180, 0.10) 100%), #F5FAFF",
        },
      },
      outlined: {
        color: "#006FE6",
        background: "linear-gradient(90deg, #158EFD 0%, #0048B4 100%)",
        backgroundClip: "text",
        webkitBackgroundClip: "text",
        webkitTextFillColor: "transparent",
        border: "#158EFD",
        svg: "#006FE6",
        hover: {
          background:
            "linear-gradient(90deg, rgba(21, 142, 253, 0.10) 0%, rgba(0, 72, 180, 0.10) 100%)",
          color: "#006FE6",
          webkitBackgroundClip: "unset",
          webkitTextFillColor: "unset",
          transition: 0,
        },
      },
    },
    feed: {
      pinned: {
        borderColor: "#158EFD",
        header: {
          background: "linear-gradient(90deg, #158EFD 0%, #0048B4 100%)",
          backgroundClip: "text",
          webkitBackgroundClip: "text",
          webkitTextFillColor: "transparent",
          pin: {
            fill: "#006FE6",
          },
        },
      },
      hidden: {
        btn: {
          borderColor: "#158EFD",
          background: "linear-gradient(90deg, #158EFD 0%, #0048B4 100%)",
          backgroundClip: "text",
          webkitBackgroundClip: "text",
          webkitTextFillColor: "transparent",
        },
      },
      postForm: {
        outlined: {
          svg: "#006FE6",
          border: "#E2E8F0",
          background: "none",
          webkitBackgroundClip: "unset",
          webkitTextFillColor: "unset",
          color: "#1E293B",
          fontWeight: 400,
        },
        contained: {
          background: "linear-gradient(90deg, #158EFD 0%, #0048B4 100%)",
        },
      },
    },
  },
  extreme: {
    tag: {
      background: "#000!important",
      color: "#FFFFFF!important",
      textTransform: "uppercase",
    },
    btn: {
      contained: {
        background: "#000",
        hover: {
          background: "#313233",
        },
        disabled: {
          background: "#818286",
        },
      },
      secondary: {
        color: "#FFFFFF",
        background: "#C4C8CC",
        hover: {
          color: "#FFFFFF",
          background: "#DDE1E6",
        },
        disabled: {
          background: "#C4C8CC",
        },
      },
      followVariant: "contained",
    },
    feed: {
      pinned: {
        borderColor: "#000",
        header: {
          color: "#000",
          background: "none",
          backgroundClip: "unset",
          webkitBackgroundClip: "unset",
          webkitTextFillColor: "unset",
          pin: {
            fill: "#000",
          },
        },
      },
      hidden: {
        btn: {
          borderColor: "#000",
          color: "#000",
          background: "none",
          backgroundClip: "unset",
          webkitBackgroundClip: "unset",
          webkitTextFillColor: "unset",
        },
      },
    },
  },
  switch: {
    main: gradientPrimary400,
    surface: gradientPrimary500,
    thumb: "#DCEAF9",
    track: "#7681A3",
  },
};
