export const typography = ({ color }) => {
  const commonProps = {
    fontFamily: `"Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC"`,
    color,
    lineHeight: 1.3,
  };
  const getFont = (fontSize, fontWeight, color, lineHeight) => {
    return {
      ...commonProps,
      ...(fontSize && { fontSize }),
      ...(fontWeight && { fontWeight }),
      ...(color && { color }),
      ...(lineHeight && { lineHeight }),
    };
  };
  return {
    inter_64_500: {
      ...getFont(64, 500, color, 1.1),
    },
    inter_32_500: {
      ...getFont(32, 500, color, 1.2),
    },
    inter_30_600: {
      ...getFont(32, 600, color, 1.2),
    },
    inter_24_600: {
      ...getFont(24, 600),
    },
    inter_20_600: {
      ...getFont(20, 600),
    },
    inter_18_600: {
      ...getFont(18, 600),
    },
    inter_18_500: {
      ...getFont(18, 500),
    },
    inter_18_400: {
      ...getFont(18, 400),
    },
    inter_17_500: {
      ...getFont(17, 500),
    },
    inter_16_600: {
      ...getFont(16, 600),
    },
    inter_16_500: {
      ...getFont(16, 500),
    },
    inter_16_400: {
      ...getFont(16, 400),
    },
    inter_15_500: {
      ...getFont(15, 500),
    },
    inter_15_400: {
      ...getFont(15, 400),
    },
    inter_14_600: {
      ...getFont(14, 600),
    },
    inter_14_500: {
      ...getFont(14, 500),
    },
    inter_14_400: {
      ...getFont(14, 500),
    },
    inter_13_600: {
      ...getFont(13, 600),
    },
    inter_13_500: {
      ...getFont(13, 500),
    },
    inter_13_400: {
      ...getFont(13, 400),
    },
    inter_12_500: {
      ...getFont(12, 500),
    },
    inter_12_400: {
      ...getFont(12, 400),
    },
    inter_11_600: {
      ...getFont(11, 600),
    },
    inter_11_500: {
      ...getFont(11, 500),
    },
    inter_11_400: {
      ...getFont(11, 400),
    },
    inter_10_600: {
      ...getFont(10, 600),
    },
    inter_9_800: {
      ...getFont(9, 800),
    },
    inter_9_600: {
      ...getFont(9, 600),
    },
    inter_9_400: {
      ...getFont(9, 400),
    },
  };
};
