import "../App.scss";

import React, { Suspense } from "react";
import LangService from "services/LangService";
import ResponseInterceptor from "services/ResponseInterceptor";
import { ViewportService } from "services/ViewportService";

import { AppUserWallet } from "../AppUserWallet";
import { ScrollToTop } from "../components/Layout/ScrollToTop/ScrollToTop";
import Analytics from "../modules/Analytics.module";
import Pusher from "../modules/Pusher.module";
import { Pixel } from "../Pixel";
import { Prompt } from "../Prompt";
import { Verification3ds } from "../Verification3ds";
import { YupCustomMethods } from "../YupCustomMethods";
import { GlobalRequests } from "./GlobalRequests";
import { MsClarity } from "./MsClarity/MsClarity";
const BackdropLoader = React.lazy(() =>
  import("components/Layout/BackdropLoader")
);
const PublicHeader = React.lazy(() =>
  import("components/Layout/PublicHeader/PublicHeader")
);
const AppSnackbar = React.lazy(() =>
  import("components/Layout/Snackbar/Snackbar")
);
const AppError = React.lazy(() =>
  import("components/Modal/ErrorModal/ErrorModal")
);
const Popup = React.lazy(() => import("components/Modal/Modal"));
// const ChallengeSuccessModal = React.lazy(() =>
//   import("components/Modal/ChallengeSuccessModal/ChallengeSuccessModal")
// );

const GlobalComponents = () => {
  return (
    <>
      {process.env.REACT_APP_ENV === "production" ? <MsClarity /> : null}
      <GlobalRequests />
      <Analytics />
      <ScrollToTop />
      <YupCustomMethods />
      <ViewportService />
      <LangService />
      <ResponseInterceptor />
      <Pusher />
      <AppUserWallet />
      <Pixel />
      <Prompt />
      <Verification3ds />
      <Suspense fallback={<div></div>}>
        <PublicHeader />
        <Popup />
        <AppSnackbar />
        <AppError />
        {/* <ChallengeSuccessModal /> */}
        <BackdropLoader />
      </Suspense>
    </>
  );
};

export default GlobalComponents;
