import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  createPost,
  deleteComment,
  deleteCommentLike,
  getComments,
  getPost,
  getPosts,
  getReactions,
  postComment,
  postCommentLike,
  postVote,
  updatePost,
} from "services/api/feedApi";

import { FEED_POSTS_TYPES, POST_STATUS } from "../constants";
import { arrayToObjConverter } from "../utils";
import { followInfluencer } from "./usersSlice";

const updatePostReactionsCount = (entity, reaction, count) => {
  if (!entity.postSummary.find((item) => item.name === reaction?.name)) {
    entity.postSummary.push({ ...reaction, count: count });
    entity.appUserReactions = [];
  } else {
    entity.postSummary = entity.postSummary.map((item) => {
      if (item.name === reaction?.name) {
        item.count = item.count + count;
      }
      return item;
    });
  }
  entity.appUserReactions = count > 0 ? [reaction] : [];
};

const initialState = {
  loading: false,
  entities: [],
  hasMore: false,
  pinnedPost: null,
  postsInReview: {},
  hasPostInReviewMore: false,
  postsInReviewLoading: false,
  hasPostInReviewTotal: 0,
  postsCategory: {},
  feed: {
    [FEED_POSTS_TYPES.FOR_YOU]: {
      loading: false,
      entities: [],
      hasMore: false,
      total: 0,
    },
    [FEED_POSTS_TYPES.CLUBS]: {
      loading: false,
      entities: [],
      hasMore: false,
      total: 0,
    },
    [FEED_POSTS_TYPES.FRIENDS]: {
      loading: false,
      entities: [],
      hasMore: false,
      total: 0,
    },
  },
  post: {
    loading: false,
    entities: null,
    posting: false,
  },
  comments: {
    entities: [],
    total: 0,
    loading: false,
    hasMore: true,
  },
  reactions: {
    entities: [],
    loading: false,
  },
};
const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    addComment: (state, action) => {
      const comment = action.payload;
      if (!comment.replyToId) {
        state.comments.entities.unshift(comment);
      } else {
        const selectedComment = state.comments.entities.find(
          (item) => item.id === comment.replyToId
        );
        if (selectedComment) {
          selectedComment.countReplies = selectedComment.countReplies
            ? selectedComment.countReplies + 1
            : 1;
          if (!selectedComment.replies) {
            selectedComment.replies = [];
          }
          selectedComment.replies.push(comment);
        }
      }
    },
    deletePostComment: (state, action) => {
      const comment = action.payload;
      if (!comment.replyToId) {
        state.comments.entities = state.comments.entities.filter(
          (item) => item.id !== comment.commentId
        );
      } else {
        const selectedComment = state.comments.entities.find(
          (item) => item.id === +comment.replyToId
        );
        if (selectedComment) {
          selectedComment.countReplies = selectedComment.countReplies - 1;
          if (selectedComment.replies?.length) {
            selectedComment.replies = selectedComment.replies.filter(
              (item) => item.id !== comment.commentId
            );
          }
        }
      }
    },
    deletePost: (state, action) => {
      const { postId } = action.payload;
      if (postId) {
        state.entities = state.entities.filter((item) => item?.id !== postId);
      }
    },
    deletePostInReview: (state, action) => {
      delete state.postsInReview[action.payload.postId];
      state.hasPostInReviewTotal = state.hasPostInReviewTotal - 1;
    },
    changePostCommentsCount: (state, action) => {
      const { postId, commentsCount } = action.payload;
      const found = state.entities.find((item) => item.id === postId);
      const postInFeed = state.feed.forYou.entities.find(
        (item) => item.id === postId
      );
      const postClubs = state.feed.clubs.entities.find(
        (item) => item.id === postId
      );

      const current = state.post.entities?.id === postId;
      if (found) found.commentsCount = commentsCount;
      if (postInFeed) postInFeed.commentsCount = commentsCount;
      if (postClubs) postClubs.commentsCount = commentsCount;
      if (current) {
        state.post.entities.commentsCount = commentsCount;
      }
    },
    resetComments: (state) => {
      state.comments.entities = [];
      state.comments.hasMore = true;
    },
    resetFeed: (state) => {
      state.entities = [];
      state.hasMore = false;
      state.postsInReview = [];
      state.hasPostInReviewMore = false;
    },
    resetFeedType: (state, action) => {
      const { type } = action.payload;
      if (state.feed[type]?.entities) {
        state.feed[type].entities = [];
      }
    },
    setComentIsLiked: (state, action) => {
      const { commentId, isLiked } = action.payload;
      let found = state.comments.entities.find((item) => item.id === commentId);

      if (!found) {
        state.comments.entities.some((comment) => {
          if (comment.replies) {
            found = comment.replies.find((reply) => reply.id === commentId);
            return Boolean(found);
          }
          return false;
        });
      }

      if (found) {
        found.isLiked = isLiked;
        const likesCount = found?.likes || 0;
        found.likes = isLiked ? likesCount + 1 : likesCount - 1;
      }
    },
    postReviewed: (state, action) => {
      const { postId, status } = action?.payload || {};
      if (status === POST_STATUS.PUBLISHED) {
        const clone = JSON.parse(JSON.stringify(state.postsInReview[postId]));
        clone.status = status;
        state.entities = [clone, ...state.entities];
        delete state.postsInReview[postId];
        state.hasPostInReviewTotal = state.hasPostInReviewTotal - 1;
      } else if (status === POST_STATUS.REJECTED) {
        if (state.postsInReview[postId]) {
          state.postsInReview[postId].status = status;
        }
      }
    },
    resetPostsInReview: (state) => {
      state.postsInReview = {};
      state.hasPostInReviewTotal = 0;
      state.hasPostInReviewMore = false;
    },
    rejectAllPostsInReview: (state) => {
      for (let key in state.postsInReview) {
        state.postsInReview[key].status = POST_STATUS.REJECTED;
      }
    },
    approveAllPostsInReview: (state) => {
      for (let key in state.postsInReview) {
        if (state.postsInReview[key].status === POST_STATUS.PENDING_REVIEW) {
          delete state.postsInReview[key];
          state.hasPostInReviewTotal = state.hasPostInReviewTotal - 1;
        }
      }
    },
    updateFeedCommentCount: (state, action) => {
      const pinnedPost = state.pinnedPost;
      const posts = pinnedPost
        ? [pinnedPost, ...state.entities]
        : state.entities;
      const post = posts.find((post) => post.id === action.payload.postId);
      if (post) {
        post.commentsCount = action.payload.totalComments;
      }
    },
    updateFeedReactionsCount: (state, action) => {
      const postId = +action.payload?.postId;
      const pinnedPost = state.pinnedPost;
      const posts = pinnedPost
        ? [pinnedPost, ...state.entities]
        : state.entities;
      const postFeed = posts.find((post) => post.id === action.payload.postId);
      const postForYou = state.feed.forYou.entities.find(
        (item) => item.id === postId
      );
      const postClubs = state.feed.clubs.entities.find(
        (item) => item.id === postId
      );
      const postEntity =
        state.post.entities?.id === postId ? state.post.entities : null;

      if (postFeed)
        updatePostReactionsCount(
          postFeed,
          action.payload.value,
          action.payload.count
        );
      if (postForYou)
        updatePostReactionsCount(
          postForYou,
          action.payload.value,
          action.payload.count
        );
      if (postClubs)
        updatePostReactionsCount(
          postClubs,
          action.payload.value,
          action.payload.count
        );
      if (postEntity)
        updatePostReactionsCount(
          postEntity,
          action.payload.value,
          action.payload.count
        );
    },
    setPost: (state, action) => {
      state.post.entities = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        const { inReview, type } = action.meta?.arg?.params || {};
        if (inReview) {
          state.postsInReviewLoading = true;
        } else if (type) {
          state.feed[type].loading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { limit, inReview, type, page } = action.meta?.arg?.params || {
          limit: 10,
          inReview: false,
        };
        const id = action.meta?.arg?.id;
        if (inReview) {
          state.postsInReview = arrayToObjConverter({
            key: "id",
            arr: action.payload.posts,
          });
          state.hasPostInReviewMore = action.payload.posts?.length >= limit;
          state.hasPostInReviewTotal = action.payload.total;
          state.postsInReviewLoading = false;
        } else if (type) {
          state.feed[type].total = action.payload.total;
          state.feed[type].loading = false;
          state.feed[type].entities =
            page > 1
              ? state.feed[type].entities.concat(action.payload.posts || [])
              : action.payload.posts || [];
          if (
            type === FEED_POSTS_TYPES.FOR_YOU ||
            (type === FEED_POSTS_TYPES.CLUBS && !id)
          ) {
            // state.feed[type].hasMore = true;
            state.feed[type].hasMore = action.payload.posts?.length >= limit;
          } else {
            state.feed[type].hasMore = action.payload.posts?.length >= limit;
          }
        } else {
          state.hasMore = action.payload.posts?.length >= limit;
          state.entities = action.payload.posts || [];
          state.pinnedPost = action.payload.pinnedPost;
          state.loading = false;
        }
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        const { inReview, type } = action.meta?.arg?.params || {
          inReview: false,
        };
        if (inReview) {
          state.postsInReviewLoading = false;
        } else if (type) {
          state.feed[type].loading = false;
        } else {
          state.loading = false;
        }
      })
      .addCase(fetchMorePosts.pending, (state, action) => {
        if (!action.meta?.arg?.params?.inReview) {
          state.loading = true;
        }
      })
      .addCase(fetchMorePosts.fulfilled, (state, action) => {
        const { limit, inReview } = action.meta?.arg?.params || {
          limit: 10,
          inReview: false,
        };

        if (inReview) {
          state.postsInReview = {
            ...state.postsInReview,
            ...arrayToObjConverter({
              key: "id",
              arr: action.payload.posts || [],
            }),
          };
          state.hasPostInReviewMore = action.payload.posts.length >= limit;
        } else {
          state.hasMore = action.payload.posts.length >= limit;
          state.entities = [...state.entities, ...(action.payload.posts || [])];
          state.loading = false;
        }
      })
      .addCase(fetchMorePosts.rejected, (state, action) => {
        if (!action.meta?.arg?.params?.inReview) {
          state.loading = false;
        }
      })

      .addCase(fetchPrevPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPrevPosts.fulfilled, (state, action) => {
        const newData = action.payload.posts || [];
        state.entities = newData.concat(state.entities);
        state.loading = false;
      })
      .addCase(fetchPrevPosts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchPrevPostsInReview.pending, (state) => {
        state.postsInReviewLoading = true;
      })
      .addCase(fetchPrevPostsInReview.fulfilled, (state, action) => {
        if (
          action.payload?.status &&
          action.payload.status !== POST_STATUS.PUBLISHED
        ) {
          state.postsInReview[action.payload.id] = action.payload;
          state.hasPostInReviewTotal = state.hasPostInReviewTotal + 1;
        }
        state.postsInReviewLoading = false;
      })
      .addCase(fetchPrevPostsInReview.rejected, (state) => {
        state.postsInReviewLoading = false;
      })
      .addCase(fetchPost.pending, (state) => {
        state.post.loading = true;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.post.loading = false;
        state.post.entities = action.payload;
      })
      .addCase(fetchPost.rejected, (state) => {
        state.post.loading = false;
      })
      .addCase(updatePostSlice.fulfilled, (state, action) => {
        const data = action?.meta?.arg || {};
        if (data.postId) {
          const dataMedia = data.files.reduce(
            (prev, acc) => {
              if (acc?.imageUrl) {
                prev.images.push(acc.imageUrl);
              }
              if (acc?.videoUrl) {
                prev.videos.push(acc.videoUrl);
              }
              return prev;
            },
            {
              images: [],
              videos: [],
            }
          );
          if (data.inReview) {
            state.postsInReview[data.postId] = {
              ...state.postsInReview[data.postId],
              ...data,
              images: dataMedia.images,
              videos: dataMedia.videos,
              data: {
                ...(data?.data ? data.data : {}),
                youtubeUrl: data.youtubeUrl,
              },
            };
          } else {
            const postIndex = state.entities.findIndex(
              (item) => item.id === data.postId
            );
            if (postIndex !== -1) {
              state.entities[postIndex] = {
                ...state.entities[postIndex],
                ...data,
                images: dataMedia.images,
                videos: dataMedia.videos,
                data: {
                  ...(data?.data ? data.data : {}),
                  youtubeUrl: data.youtubeUrl,
                },
              };
            }
          }
        }
      })
      .addCase(onPostComment.pending, (state) => {
        state.post.posting = true;
      })
      .addCase(onPostComment.fulfilled, (state) => {
        state.post.posting = false;
      })
      .addCase(onPostComment.rejected, (state) => {
        state.post.posting = false;
      })
      .addCase(fetchComments.pending, (state) => {
        state.comments.loading = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments.loading = false;
        if (!action.payload.comments?.length) state.comments.hasMore = false;
        state.comments.entities = action.payload.comments || [];

        state.comments.total = action.payload.total;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.comments.loading = false;
      })

      .addCase(fetchMoreComments.pending, (state) => {
        state.comments.loading = true;
      })
      .addCase(fetchMoreComments.fulfilled, (state, action) => {
        state.comments.loading = false;
        const newData = action.payload.comments || [];
        state.comments.entities = state.comments.entities.concat(newData);
        if (!newData.length) state.comments.hasMore = false;
      })
      .addCase(fetchMoreComments.rejected, (state) => {
        state.comments.loading = false;
      })

      .addCase(fetchPrevComments.pending, (state) => {
        state.comments.loading = true;
      })
      .addCase(fetchPrevComments.fulfilled, (state, action) => {
        const newData = action.payload.comments || [];
        state.comments.entities = newData.concat(state.comments.entities);
      })
      .addCase(fetchPrevComments.rejected, (state) => {
        state.comments.loading = false;
      })
      .addCase(fetchReactions.pending, (state) => {
        state.reactions.loading = true;
      })
      .addCase(fetchReactions.fulfilled, (state, action) => {
        state.reactions.loading = false;
        state.reactions.entities = action.payload;
      })
      .addCase(fetchReactions.rejected, (state) => {
        state.reactions.loading = false;
      })
      .addCase(fetchCommentReplies.fulfilled, (state, action) => {
        const { commentId } = action.meta.arg.params;
        state.comments.entities = state.comments.entities.map((item) => {
          if (item.id === commentId) {
            item.replies = _.uniqBy(
              [...(item.replies || []), ...action.payload?.comments],
              "id"
            );
          } else if (
            item.replies &&
            item.replies.some((reply) => reply.id === commentId)
          ) {
            item.replies = _.uniqBy(
              [...(item.replies || []), ...action.payload?.comments],
              "id"
            );
          }
          return item;
        });
      })

      .addCase(deleteComments.fulfilled, (state, action) => {
        const { commentId } = action.meta.arg;
        const index = state.comments.entities.findIndex(
          (comment) => comment.id === commentId
        );
        if (index > -1) {
          state.comments.entities.splice(index, 1);
        } else {
          for (let i = 0; i < state.comments.entities.length; i++) {
            const comment = state.comments.entities[i];
            const replyIndex = comment.replies?.findIndex(
              (reply) => reply.id === commentId
            );
            if (replyIndex > -1) {
              comment.replies.splice(replyIndex, 1);
              break;
            }
          }
        }
      })
      .addCase(followInfluencer.fulfilled, (state, action) => {
        const influencers = state.feed[FEED_POSTS_TYPES.FOR_YOU].entities
          .filter(
            (item) => item?.influencer?.id === +action?.meta?.arg?.influencerId
          )
          .map((item) => item.influencer);
        influencers.forEach((influencer) => {
          if (influencer) {
            influencer.isFollowing = true;
          }
        });
      });
  },
});
export const {
  addComment,
  deletePostComment,
  deletePost,
  changePostCommentsCount,
  resetComments,
  resetFeed,
  setComentIsLiked,
  deletePostInReview,
  postReviewed,
  rejectAllPostsInReview,
  approveAllPostsInReview,
  resetPostsInReview,
  updateFeedCommentCount,
  setPost,
  resetFeedType,
  updateFeedReactionsCount,
} = feedSlice.actions;

export default feedSlice.reducer;

export const newPost = createAsyncThunk(
  "community/posts/create",
  async (params) => await createPost(params)
);

export const updatePostSlice = createAsyncThunk(
  "community/posts/update",
  async (params) => await updatePost(params)
);

export const fetchPosts = createAsyncThunk("feed/data", async (params) => {
  const response = await getPosts(params);
  return response;
});
export const fetchMorePosts = createAsyncThunk("feed/more", async (params) => {
  const response = await getPosts(params);
  return response;
});
export const fetchPrevPosts = createAsyncThunk(
  "feed/prev",
  async (params, { getState }) => {
    const state = getState();
    const next = nextPostId(state);
    const response = await getPosts({ id: params.id, params: { next } });
    return response;
  }
);
export const fetchPrevPostsInReview = createAsyncThunk(
  "feed/prevInReview",
  async (params) => {
    const response = await getPost(params.postId, true);
    return response;
  }
);
export const fetchPost = createAsyncThunk(
  "feed/post",
  async ({ postId, isAuth }) => {
    const response = await getPost(postId, isAuth);
    return response;
  }
);
export const onPostComment = createAsyncThunk(
  "comment/post",
  async (params) => {
    try {
      const response = await postComment(params);
      return response;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return err.data;
    }
  }
);
export const onPostPollVote = createAsyncThunk(
  "vote/post",
  async (params, { rejectWithValue }) => {
    try {
      const response = await postVote(params);
      return response;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchComments = createAsyncThunk(
  "feed/comments",
  async (params) => {
    const response = await getComments(params);
    return response;
  }
);

export const deleteComments = createAsyncThunk(
  "feed/comments/delete",
  async (params) => {
    const response = await deleteComment(params);
    return response;
  }
);

export const fetchCommentReplies = createAsyncThunk(
  "feed/comments-replies",
  async (params) => {
    const response = await getComments(params);
    return response;
  }
);

export const fetchPrevComments = createAsyncThunk(
  "feed/comments/prev",
  async (params, { getState }) => {
    const state = getState();
    const next = nextCommentId(state);
    const response = await getComments({ id: params.id, params: { next } });
    return response;
  }
);
export const fetchMoreComments = createAsyncThunk(
  "feed/comments/more",
  async (params) => {
    const response = await getComments(params);
    return response;
  }
);

export const fetchReactions = createAsyncThunk("feed/reactions", async () => {
  const response = await getReactions();
  return response;
});
export const likeComment = createAsyncThunk("comment/like", async (params) => {
  const response = await postCommentLike(params);
  return response;
});
export const unlikeComment = createAsyncThunk(
  "comment/unlike",
  async (params) => {
    const response = await deleteCommentLike(params);
    return response;
  }
);
export const prevPostId = (state) => {
  const posts = state.feed.entities;
  return posts.length ? posts[posts.length - 1]?.id : null;
};
export const nextPostId = (state) => {
  const posts = state.feed.entities;
  return posts.length ? posts[0]?.id : null;
};

export const prevCommentId = (state) => {
  const comments = state.feed.comments?.entities;
  return comments.length ? comments[comments.length - 1]?.id : null;
};
export const nextCommentId = (state) => {
  const comments = state.feed.comments?.entities;
  return comments.length ? comments[0]?.id : null;
};
